import { ReactComponent as PaperIcon } from "src/assets/icons/paper.svg";
import { memo, useState } from "react";

interface Props {
  text: string;
  className?: string;
}

const CopyText = ({ text, className }: Props) => {
  const [message, setMessage] = useState("");
  const copyToClipBoard = async (textCopy: string) => {
    try {
      await navigator.clipboard.writeText(textCopy);
      setMessage("Copied");
      setTimeout(() => {
        setMessage("");
      }, 1000);
    } catch (error) {
      setMessage("Failed to Copy");
    }
  };

  return (
    <div className="relative hover:text-secondary-main-700">
      <div
        role="button"
        tabIndex={0}
        onKeyUp={(e) => {
          if (e.code === "Enter") {
            copyToClipBoard(text);
          }
        }}
        onClick={() => copyToClipBoard(text)}
        className={["relative", className].join(" ")}
      >
        <PaperIcon className="h-7 w-7 p-1 cursor-pointer fill-current stroke-0  " />
      </div>
      <div
        className={[
          "absolute bottom-full text-white bg-primary p-2 rounded-md shadow-md animate-SlideUpFadeIn",
          message === "" ? "hidden" : "block",
        ].join(" ")}
      >
        {message}
      </div>
    </div>
  );
};

export default memo(CopyText);
