interface Props {
  name: string;
  value: string;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
  error?: string;
  label: string;
  helper?: string;
  type?: string;
  maxLength?: number;
  minLength?: number;
  placeholder: string;
  className?: string;
  disabled?: boolean;
  isRequired?: boolean;
  classNameInput?: string;
}

const FormInputText: React.FC<Props> = ({
  name,
  value = "",
  onChange,
  error = "",
  placeholder,
  type,
  maxLength,
  minLength,
  label,
  disabled,
  className,
  isRequired,
  classNameInput,
}) => {
  const isValid = error?.length < 1;
  return (
    <div
      className={[
        "relative  w-full flex flex-col space-y-1",
        disabled ? "opacity-50 transition-opacity" : "",
        className,
      ].join(" ")}
    >
      {label && (
        <label htmlFor={name} className="font-semibold text-base">
          {label}
          {isRequired && <span className=" text-spice">*</span>}
        </label>
      )}
      <div className="relative">
        <input
          type={type || "text"}
          name={name}
          maxLength={maxLength}
          minLength={minLength}
          id={name}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          disabled={disabled}
          autoComplete="off"
          className={[
            " w-full h-11 bg-white py-2 px-4 border focus:outline-none rounded-lg text-base",
            !isValid && !disabled ? "focus:border-custom-100 border-spice" : "",
            isValid
              ? " border-neutral-light-main-600 focus:border-[#E2E2E2]"
              : "",
            classNameInput,
          ].join(" ")}
        />
        {error && !disabled && (
          <div className=" msgFormField text-spice">{error}</div>
        )}
      </div>
    </div>
  );
};

export default FormInputText;
