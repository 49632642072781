import { useState } from "react";

interface Props {
  name: string;
  value: string;
  onChange(x: { n: string; v: string | number }): void;
  error?: string;
  placeholder: string;
  type?: string;
  disabled?: boolean;
  maxLength?: number;
  minLength?: number;
  label?: string;
  className?: string;
  classNameInput?: string;
  isRequired?: boolean;
}

const FormInputMobileNumber = ({
  name,
  value,
  onChange,
  error = "",
  placeholder,
  type,
  disabled,
  maxLength,
  minLength,
  label,
  className,
  classNameInput,
  isRequired,
}: Props) => {
  const isValid = error?.length < 1;

  const [focus, setFocus] = useState(false);

  const handleInitPrefix = () => {
    if (value && value.includes("(") && value.includes(")")) {
      const prefixStartIndex = value.indexOf("(");
      const prefixLastIndex = value.indexOf(")");
      return value.slice(prefixStartIndex, prefixLastIndex + 1);
    }
    return "+63";
  };

  const [prefixValue, setPrefixValue] = useState(handleInitPrefix());
  const handleInitMobileNumber = () => {
    if (value) {
      value.replace(/\D/g, "");
      return value.slice(-12);
    }
    return "";
  };
  const [mobileNumber, seMobileNumber] = useState(handleInitMobileNumber());

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value;
    input = input.replace(/\D/g, ""); // remove all non-numeric characters
    input = input.substring(0, 10); // limit the input to 10 characters (for a US number)
    input = input.replace(/(\d{3})(\d{4})(\d{3})/, "$1 $2 $3");
    seMobileNumber(input);
    onChange({ n: name, v: `+63 ${input}` });
  };

  return (
    <div
      className={[
        " flex flex-col w-full space-y-1",
        disabled && "opacity-50 transition-opacity",
        className,
      ].join(" ")}
    >
      {label && (
        <label htmlFor={name} className="font-semibold text-base">
          {label}
          {isRequired && <span className=" text-spice">*</span>}
        </label>
      )}
      <div className="relative">
        <div
          className={[
            " flex h-11 w-full rounded-lg  bg-white  border  outline-none px-4 text-base",
            isValid && focus ? " border-[#E2E2E2]" : "",
            !isValid ? "border-spice " : "",
            disabled && "transition-opacity cursor-not-allowed",
            classNameInput,
          ].join(" ")}
        >
          <input
            type={type || "text"}
            value={prefixValue}
            disabled
            autoComplete="off"
            className=" bg-[transparent] border-none outline-none h-full w-[2rem] select-none"
          />
          <input
            type={type || "text"}
            name={name}
            maxLength={maxLength}
            minLength={minLength}
            id={name}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            placeholder={"XXX XXXX XXX"}
            onChange={handleChange}
            value={mobileNumber}
            disabled={disabled}
            autoComplete="off"
            className=" bg-[transparent] border-none outline-none h-full flex-1"
          />
        </div>

        {error && <div className=" msgFormField text-spice">{error}</div>}
      </div>
    </div>
  );
};

export default FormInputMobileNumber;
