import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainLayout from "src/components/layouts/mainlayout";
import NotFound from "src/pages/404";
import HomePage from "./pages";
import HomeLayout from "./components/layouts/HomeLayout";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<MainLayout />}>
          <Route element={<HomeLayout />}>
            <Route path="/" element={<HomePage />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
