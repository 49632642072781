import { ReactComponent as CheckIcon } from "src/assets/icons/check-round.svg";
import { ReactComponent as ArrowBackIcon } from "src/assets/icons/arrow-back.svg";
import { memo } from "react";
import { Link } from "react-router-dom";
import { PurchaseModel } from "src/models/PurchaseModel";
import CopyText from "src/components/elements/CopyText";

interface Props {
  successData: PurchaseModel;
}

const ContentSuccess = ({ successData }: Props) => {
  return (
    <div className=" flex flex-col justify-center">
      <div className="w-full relative p-8 flex flex-col shadow-[0px_4px_7px_rgba(0,_0,_0,_0.1)] rounded-xl xl:max-w-[470px] space-y-6 xl:self-end bg-white">
        <div className="flex items-center justify-between text-custom-black w-full">
          <Link to="/">
            <ArrowBackIcon className=" h-7 w-7 " />
          </Link>

          <div className=" text-lg  font-medium">Prepaid WiFi</div>
        </div>
        <div className="bg-primary  px-8 py-20 rounded-2xl space-y-2 font-roboto">
          <div className=" max-w-[18.5rem] w-full mx-auto flex flex-col items-center text-center text-white space-y-4">
            <div className="flex items-center flex-col space-y-2 font-medium">
              <CheckIcon />
              <div>Successful</div>
            </div>
            <div className=" text-2xl font-bold">Your Pin Code</div>
            <div className="flex flex-col space-y-2 py-4 w-full">
              {Object.entries(successData.pin).map(([key, value]) => (
                <div className="flex items-center space-x-4 justify-between font-bold text-lg">
                  <div className=" capitalize">{key}</div>
                  <div className="flex items-center">
                    <div className="underline mr-2">{value}</div>
                    <CopyText text={value || ""} />
                  </div>
                </div>
              ))}
            </div>
            <p className=" text-base">
              We will send these details to you via email and SMS, using the
              contact information you provided.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ContentSuccess);
