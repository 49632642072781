import { Modal } from "src/components/elements";
import { Ref } from "react";
import { ModalActionModel } from "src/models/modal-action-model";

interface propsModel {
  modalRef: Ref<ModalActionModel>;
  errorMessage: string;
}

const ModalUnexpectedError: React.FC<propsModel> = ({
  modalRef,
  errorMessage = "Something Wen't Wrong",
}) => {
  return (
    <Modal
      ref={modalRef}
      isSmallModal={true}
      title="Error"
      maxWidth={"max-w-[600px]"}
    >
      <div className="flex flex-col justify-center h-full flex-1">
        <p className=" text-md text-center ">{errorMessage}</p>
      </div>
    </Modal>
  );
};

export default ModalUnexpectedError;
