export const productList = [
  {
    id: 1,
    price: "₱20.00",
    name: "Fatpipe WiFi 1 Day",
    code: "FPW1D",
    desc: "1 Day Validity",
  },
  {
    id: 2,
    price: "₱50.00",
    name: "Fatpipe WiFi 3 Day",
    code: "FPW3D",
    desc: "3 Day Validity",
  },
  {
    id: 3,
    price: "₱99.00",
    name: "Fatpipe WiFi 7 Day",
    code: "FPW7D",
    desc: "7 Day Validity",
  },
  {
    id: 4,
    price: "₱399.00",
    name: "Fatpipe WiFi 30 Day",
    code: "FPW30D",
    desc: "30 Day Validity",
  },
];

const WIDTH = 500;
const HEIGHT = 600;
const LEFT = window.screenX + (window.outerWidth - WIDTH) / 2;
const TOP = window.screenY + (window.outerHeight - HEIGHT) / 2 - 50;

export const WINDOWFEATURES = `width=${WIDTH},height=${HEIGHT},left=${LEFT},top=${TOP},location=yes,toolbar=no,menubar=no,scrollbars=yes,resizable=yes`;
