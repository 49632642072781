import { ReactComponent as ArrowBackIcon } from "src/assets/icons/arrow-back.svg";
import FormInputMobileNumber from "src/components/elements/FormInputMobileNumber";
import FormInputText from "src/components/elements/FormInputText";
import ModalDataPrivacy from "./ModalDataPrivacy";
import { Link } from "react-router-dom";
import { SyntheticEvent, useEffect, useState } from "react";
import { BtnSubmit, Form } from "src/components/elements";
import useMountAndOpenModal from "src/hooks/useMountAndOpenModal";
import axios from "axios";
import { PurchaseModel } from "src/models/PurchaseModel";
import useQuery from "src/hooks/useQuery";
import ModalTandC from "./ModalTandC";
import ModalUnexpectedError from "src/components/common/ModalUnexpectedError";

const ContentSelected = ({
  selectedProduct,
  setConfirmData,
}: {
  setConfirmData: (x: any) => void;
  selectedProduct: {
    id: number;
    price: string;
    name: string;
    code: string;
    desc: string;
  } | null;
}) => {
  const SUCCESS_URL = `${window.location.origin}?reference_id=$reference_id&mobile_number=$mobile_number`;
  const FAILED_URL = `${window.location.origin}?status=failed`;
  const query = useQuery();
  const [modal, createModal] = useMountAndOpenModal();
  const [loading, setLoading] = useState(false);
  const [isValidForm, setIsValidForm] = useState(false);

  const [form, setForm] = useState({
    mobile_number: "",
    email: "",
  });

  const [errorFields, setErrorFields] = useState<null | {
    mobile_number: string[];
    email: string[];
  }>({
    mobile_number: [""],
    email: [""],
  });

  const handleOnChange = ({ n, v }: { n: string; v: string }) => {
    setForm({
      ...form,
      [n]: v,
    });
    setErrorFields(null);
  };

  const handleSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (form.mobile_number.length < 13) {
      return setErrorFields({
        mobile_number: ["Mobile number is invalid."],
        email: [""],
      });
    }
    setLoading(true);
    try {
      const {
        data,
      }: {
        data: {
          data: PurchaseModel;
        };
      } = await axios({
        method: "post",
        baseURL: process.env.REACT_APP_API_HOSTNAME,
        url: "/v1/purchase",
        data: {
          code: query.get("code"),
          mobile_number: form.mobile_number.split(" ").join("").slice(-10),
          email: form.email,
          success_redirect_url: SUCCESS_URL,
          failure_redirect_url: FAILED_URL,
        },
      });
      setConfirmData(data?.data);
      setLoading(false);
    } catch (error: any) {
      const res = error.response;
      const errors = {
        errorCode: res?.status || error.code,
        errorFormField: res?.data.errors,
        errorMessage: res?.data.message || error.message,
        exception: res?.data.exception,
      };
      setLoading(false);
      createModal(ModalUnexpectedError, { errorMessage: errors.errorMessage });
    }
  };

  useEffect(() => {
    setIsValidForm(false);
    Object.entries(form).forEach(([key, value]) => {
      if (
        value?.toString().length > 0 &&
        key === "mobile_number" &&
        value.toString().length > 4
      ) {
        setIsValidForm(true);
      }
    });
  }, [form]);

  return (
    <div className=" flex flex-col justify-center">
      {modal}
      <Form
        onSubmit={handleSubmit}
        className="w-full relative p-8 flex flex-col shadow-[0px_4px_7px_rgba(0,_0,_0,_0.1)] rounded-xl xl:max-w-[470px] space-y-6 xl:self-end bg-white"
      >
        <div className="flex items-center justify-between text-custom-black w-full">
          <Link to={"/"}>
            <ArrowBackIcon className=" h-7 w-7 " />
          </Link>

          <div className=" text-lg  font-medium">Prepaid WiFi</div>
        </div>
        <div className="bg-primary  p-8 rounded-2xl space-y-2 font-roboto flex flex-col  text-white">
          <div className=" text-4xl font-bold ">{selectedProduct?.price}</div>
          <div>
            <div className=" text-[1.75rem] font-bold">
              {selectedProduct?.name}
            </div>
            <p className=" text-lg font-normal">{selectedProduct?.desc}</p>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <FormInputMobileNumber
            name="mobile_number"
            isRequired
            value={form.mobile_number}
            onChange={handleOnChange}
            error={errorFields?.mobile_number?.[0]}
            label="Mobile Number"
            disabled={loading}
            placeholder="Enter Mobile Number"
            classNameInput="scroll-mt-[8rem]"
          />
          <FormInputText
            name="email"
            value={form.email}
            onChange={(e) => handleOnChange({ n: "email", v: e.target.value })}
            error={errorFields?.email?.[0]}
            label="Email (Optional)"
            disabled={loading}
            placeholder="Enter Email"
            classNameInput="scroll-mt-[8rem]"
          />
        </div>
        <p className=" text-base">
          By clicking here, you agree with{" "}
          <button
            type="button"
            onClick={() => createModal(ModalTandC)}
            className="font-bold"
          >
            Terms of Service
          </button>
          {` and `}
          <button
            type="button"
            onClick={() => createModal(ModalDataPrivacy)}
            className="font-bold"
          >
            Data Privacy
          </button>
        </p>

        <BtnSubmit disabled={loading || !isValidForm} title="Continue" />
      </Form>
    </div>
  );
};

export default ContentSelected;
