import { ReactElement } from "react";
import { BtnLink, Img } from "src/components/elements";

const NotFound = (): ReactElement => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 flex-1">
      <div className="flex items-start flex-col space-y-4 justify-center">
        <div className=" text-7xl font-bold">
          Ooops! Looks like <br />
          you’re lost.
        </div>
        <p className=" max-w-[453px] text-xl">
          This page doesn’t exist or was removed. We suggest you go back to
          home.
        </p>
        <div>
          <BtnLink linkTo="/" title="Go Back" className="w-auto" />
        </div>
      </div>
      <div className="flex justify-center items-center">
        <Img
          src="/images/404.png"
          width="530"
          height="474"
          alt=""
          className=" object-contain px-4"
        />
      </div>
    </div>
  );
};

export default NotFound;
