interface Props {
  children?: React.ReactNode;
  className?: string;
  onSubmit(e: React.FormEvent): void;
}

const Form: React.FC<Props> = ({ children, onSubmit, className }) => {
  return (
    <form onSubmit={onSubmit} className={className}>
      {children}
    </form>
  );
};

export default Form;
