import { Modal } from "src/components/elements";
import { Ref } from "react";
import { ModalActionModel } from "src/models/modal-action-model";

interface propsModel {
  modalRef: Ref<ModalActionModel>;
}

const ModalDataPrivacy: React.FC<propsModel> = ({ modalRef }) => {
  return (
    <Modal
      ref={modalRef}
      isSmallModal={true}
      title="Data Privacy"
      maxWidth={"max-w-[800px]"}
    >
      <p className=" text-justify text-xs">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. In qui fugit
        dolorem illo culpa deserunt quas excepturi dicta aliquid esse mollitia
        neque voluptates eveniet eos non dolores numquam, ut eum reiciendis
        architecto tempora. Consequuntur molestiae repellat iure ratione
        mollitia at, fuga sapiente atque architecto accusamus animi ea pariatur
        officia asperiores cupiditate dolore quo unde consequatur! Optio
        deserunt recusandae iste. Consequuntur doloribus exercitationem
        accusantium esse quae eaque iusto reiciendis voluptatem commodi.
        <br />
        <br />
        Nesciunt commodi possimus, quia deserunt magnam fugiat nihil sint
        dolores soluta eaque? Numquam neque eligendi consequuntur repellendus
        quo corporis delectus temporibus, necessitatibus qui enim deserunt
        soluta labore commodi voluptas ex eum error? Fuga, adipisci. Quos amet
        excepturi aliquam quisquam nostrum qui, et repudiandae modi vel impedit
        recusandae consequuntur, obcaecati ut fugit quam magnam exercitationem
        error aspernatur. Similique possimus ipsum perspiciatis neque qui sit
        dicta, minus veniam quam error, eius fuga cum voluptatibus ducimus
        voluptate molestiae officiis distinctio provident vero maxime ratione
        <br />
        <br />
        sapiente debitis numquam! Dicta sequi sit iure iusto excepturi
        perspiciatis quaerat ex quo temporibus repellendus dolore magni
        necessitatibus id neque, alias tempora, dolores dolorem voluptatum
        assumenda, aut voluptatibus quae dolorum ipsam unde. Vitae voluptatem
        dolores iure cumque neque enim illo consectetur? Odio eligendi incidunt
        ipsam suscipit autem sunt corrupti quae, dolor at provident minus
        similique culpa! Ipsum odio iste deleniti eos est. Tenetur accusantium
        reiciendis eos quae velit dolorem natus, doloremque, vitae vel culpa
        obcaecati magni consequatur. Incidunt neque nihil illum sunt odio beatae
        exercitationem repudiandae reiciendis laboriosam, tempora libero officia
        saepe delectus doloremque rem, mollitia ullam sit. Natus, quas excepturi
        <br />
        <br />
        rem doloremque quis expedita molestias veniam inventore incidunt est
        nulla quo ut, quam, aperiam itaque fugiat quidem temporibus tenetur
        ratione vel deserunt eos? Sed omnis necessitatibus neque exercitationem,
        molestias qui aperiam autem sequi vel magni consectetur, cumque quidem
        voluptatum. Necessitatibus earum, quos doloremque molestiae ut rem
        deserunt odit!
      </p>
    </Modal>
  );
};

export default ModalDataPrivacy;
