import React, { useMemo, useState } from "react";
import ReactDOM from "react-dom";

interface EpaygamesButtonProps {
  payment_link: string;
  window_title?: string;
  btn_title?: string;
  btn_classname?: string;
  disabled: boolean;
  overlay_classname?: string;
  modal_classname?: string;
  modal_btn_classname?: string;
  success_url?: string;
  failed_url?: string;
  onSuccess?: () => void;
  onStart?: () => void;
  onClose?: () => void;
}

const EpWindow = ({
  handleCloseWindow,
  handleOpenPaymentLink,
}: {
  handleCloseWindow: () => void;
  handleOpenPaymentLink: () => void;
}) => {
  return ReactDOM.createPortal(
    <div
      className="fixed inset-0"
      style={{
        zIndex: 9999,
      }}
    >
      <button
        type="button"
        onClick={() => {
          handleCloseWindow();
        }}
        style={{
          zIndex: 10000,
        }}
        className="absolute cursor-pointer top-8 right-8 bg-transparent h-6 w-6 text-white"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <div
        role="button"
        onClick={() => handleOpenPaymentLink()}
        className="h-full w-full flex items-center justify-center bg-black flex-col bg-opacity-80 bg-clip-padding text-white border-2 border-gray-400 backdrop-blur-[1px]"
      >
        <div
          className="px-4 flex flex-col items-center text-center text-base space-y-4 max-w-lg"
          role="button"
          onClick={() => handleOpenPaymentLink()}
        >
          <img
            src="https://www.epaygames.io/logo/ep_wordmark_light.png"
            alt="Epaygames Payment Gateway"
          />
          <div>
            Don’t see the secure Epaygames browser? We’ll help you re-launch the
            window to complete your purchase
          </div>
          <div className="underline font-bold">Click to Continue</div>
        </div>
      </div>
    </div>,
    document.body
  );
};

const EpaygamesButton: React.FC<EpaygamesButtonProps> = ({
  payment_link,
  window_title = "Epaygames Payment Gateway",
  btn_classname,
  disabled,
  btn_title = "MAKE PAYMENT",
  success_url,
  failed_url,
  onSuccess,
  onStart,
  onClose,
}) => {
  const WIDTH = 500;
  const HEIGHT = 600;
  const LEFT = window.screenX + (window.outerWidth - WIDTH) / 2;
  const TOP = window.screenY + (window.outerHeight - HEIGHT) / 2 - 50;

  const WINDOWFEATURES = useMemo(
    () =>
      `width=${WIDTH},height=${HEIGHT},left=${LEFT},top=${TOP},location=yes,toolbar=no,menubar=no,scrollbars=yes,resizable=yes`,
    [WIDTH, HEIGHT, LEFT, TOP]
  );

  const [epaygamesWindow, setEpaygamesWindow] = useState<Window | null>(null);

  const handleCloseWindow = () => {
    if (onClose) {
      onClose();
    }
    setEpaygamesWindow(null);
    document.body.style.overflow = "auto";
  };

  const handleOpenPaymentLink = async () => {
    if (epaygamesWindow && !epaygamesWindow.closed) {
      return epaygamesWindow.focus();
    }

    if (onStart) {
      onStart();
    }

    const newWindow = window.open(payment_link, window_title, WINDOWFEATURES);
    setEpaygamesWindow(newWindow);
    if (newWindow) {
      document.body.style.overflow = "hidden";
      const checkWindowStatus = () => {
        if (newWindow.innerWidth <= WIDTH) {
          if (newWindow.location && newWindow.location.href) {
            switch (newWindow.location.href) {
              case success_url:
                if (success_url) {
                  newWindow.close();
                  window.location.href = success_url;
                }
                break;

              case failed_url:
                if (failed_url) {
                  newWindow.close();
                  window.location.href = failed_url;
                }
                break;

              default:
                if (
                  (success_url?.includes("$") &&
                    newWindow.location.href.includes("reference_id") &&
                    newWindow.location.href.includes("mobile_number")) ||
                  newWindow.location.href.includes("status=failed")
                ) {
                  newWindow.close();
                  window.location.href = newWindow.location.href;
                }
                break;
            }
          }
        } else {
          document.body.style.overflow = "auto";
          setEpaygamesWindow(null);
          clearInterval(intervalId);
        }
      };

      const intervalId = setInterval(() => {
        try {
          if (newWindow.closed) {
            if (onSuccess) {
              onSuccess();
            }
            document.body.style.overflow = "auto";
            clearInterval(intervalId);
            setEpaygamesWindow(null);
          } else {
            checkWindowStatus();
          }
        } catch (error) {
          console.log(error);
        }
      }, 1000); // check every 1 second

      window.addEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          newWindow.close();
        }
      });
    }
  };

  return (
    <div>
      {epaygamesWindow && (
        <EpWindow
          handleCloseWindow={handleCloseWindow}
          handleOpenPaymentLink={handleOpenPaymentLink}
        />
      )}
      <button
        tabIndex={0}
        onKeyUp={(e) => {
          if (e.code === "Enter") {
            handleOpenPaymentLink();
          }
        }}
        onClick={() => handleOpenPaymentLink()}
        className={btn_classname}
        disabled={disabled}
      >
        {btn_title}
      </button>
    </div>
  );
};

export default EpaygamesButton;
