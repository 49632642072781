interface Props {
  alt: string;
  src: string;
  className?: string;
  draggable?: boolean;
  height?: string;
  width?: string;
}

const Img: React.FC<Props> = ({
  alt,
  src,
  className,
  draggable,
  height,
  width,
}) => {
  return (
    <img
      alt={alt || "Img"}
      src={src}
      height={height}
      width={width}
      className={className}
      draggable={draggable}
    />
  );
};

export default Img;
