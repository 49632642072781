import { Outlet } from "react-router-dom";
import { BtnLink } from "../elements";

const HomeLayout = () => {
  return (
    <div className="flex-1 grid grid-cols-1 xl:grid-cols-2 max-w-[33.125rem] xl:max-w-none mx-auto xl:mx-0 py-8 gap-x-4 gap-y-12 relative">
      <div className=" row-start-2 xl:row-start-auto flex flex-col items-start space-y-4 max-w-[33.125rem] xl:sticky xl:h-min xl:top-[31%]">
        <h1 className="text-secondary text-7xl font-bold">
          <span className=" text-primary"> Wi-Fi</span> Prepaid <br /> Access
        </h1>
        <p className=" text-[22px]">
          Avail our new Fatpipe prepaid WiFi promos and enjoy unlimited data
          access.
        </p>
        <div>
          <BtnLink title="How to Avail Fatpipe WiFi" linkTo="/" isOutline />
        </div>
      </div>

      <Outlet />
    </div>
  );
};

export default HomeLayout;
