import { ReactNode } from "react";
import { Link } from "react-router-dom";

interface Props {
  linkTo: string;
  title: string;
  className?: string;
  isOutline?: boolean;
  prefixIcon?: ReactNode;
}

const BtnLink: React.FC<Props> = ({
  linkTo,
  title,
  className,
  isOutline,
  prefixIcon,
}) => {
  return (
    <Link
      to={linkTo}
      className={[
        "text-lg text-center flex items-start justify-center space-x-2 font-medium font-roboto",
        "my-2 p-[10px] rounded-xl  w-full  border ",
        isOutline
          ? "border-[#616467] text-[#616467]"
          : "border-transparent bg-primary text-white",
        isOutline && "hover:bg-primary hover:border-primary hover:text-white",
        className,
      ].join(" ")}
    >
      {prefixIcon}
      <div>{title}</div>
    </Link>
  );
};

export default BtnLink;
