import useQuery from "src/hooks/useQuery";
import ContentInit from "./ContentInit";
import ContentSelected from "./ContentSelected";
import { useEffect, useState } from "react";
import { productList } from "./util";
import ContentConfirm from "./ContentConfirm";
import ContentSuccess from "./ContentSuccess";
import axios from "axios";
import ModalUnexpectedError from "src/components/common/ModalUnexpectedError";
import useMountAndOpenModal from "src/hooks/useMountAndOpenModal";

const ContentHome = () => {
  const query = useQuery();
  const [loading, setLoading] = useState(false);
  const [modal, createModal] = useMountAndOpenModal();

  const [selectedProduct, setSelectedProduct] = useState<null | {
    id: number;
    price: string;
    name: string;
    code: string;
    desc: string;
  }>(null);

  const [confirmData, setConfirmData] = useState(null);

  const [successData, setSuccessData] = useState(null);

  const handleRetrievePurchase = async ({
    reference_id,
    mobile_number,
  }: {
    reference_id: string;
    mobile_number: string;
  }) => {
    setLoading(true);
    try {
      const { data } = await axios({
        method: "get",
        url: `/v1/purchase`,
        baseURL: process.env.REACT_APP_API_HOSTNAME,
        params: {
          reference_id,
          mobile_number,
        },
      });
      setSuccessData(data.data);
      setLoading(false);
    } catch (error: any) {
      const res = error.response;
      const errors = {
        errorCode: res?.status || error.code,
        errorFormField: res?.data.errors,
        errorMessage: res?.data.message || error.message,
        exception: res?.data.exception,
      };
      createModal(ModalUnexpectedError, { errorMessage: errors.errorMessage });
      setLoading(false);
    }
  };

  useEffect(() => {
    const code = query.get("code");
    const reference_id = query.get("reference_id");
    const mobile_number = query.get("mobile_number");

    if (reference_id && mobile_number) {
      handleRetrievePurchase({
        reference_id,
        mobile_number,
      });
    }
    if (!code) {
      setSuccessData(null);
      setConfirmData(null);
      setSelectedProduct(null);
      return;
    }
    const newSelected = productList.find((x) => x.code === code);
    if (newSelected) {
      setSelectedProduct(newSelected);
    }
  }, [query]);

  useEffect(() => {
    if (query.get("status") === "failed") {
      createModal(ModalUnexpectedError, {
        errorMessage: "The payment was unsuccessful.",
      });
    }
  }, [query]);

  if (!loading && successData) {
    return <ContentSuccess successData={successData} />;
  }

  if (!loading && confirmData) {
    return <ContentConfirm confirmData={confirmData} />;
  }

  if (!loading && selectedProduct) {
    return (
      <ContentSelected
        setConfirmData={setConfirmData}
        selectedProduct={selectedProduct}
      />
    );
  }

  if (!loading) {
    return (
      <>
        {modal}
        <ContentInit />
      </>
    );
  }

  return (
    <div>
      {modal}
      <div className=" bg-white fixed inset-0 z-50 flex flex-col justify-center items-center">
        Loading...
      </div>
    </div>
  );
};

export default ContentHome;
