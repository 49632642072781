import { Outlet } from "react-router-dom";
import Nav from "./Nav";

const MainLayout = () => {
  return (
    <div className=" max-w-[1248px] w-full mx-auto px-4">
      <Nav />
      <main className=" min-h-[calc(100vh-14rem)] flex flex-col mt-[7rem]">
        <Outlet />
      </main>
      <footer className=" h-[7rem] flex justify-center flex-col px-2">
        <p className="text-center xl:text-right">Copyright © Fatpipewireless</p>
      </footer>
    </div>
  );
};

export default MainLayout;
