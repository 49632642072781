import { Link } from "react-router-dom";
import { productList } from "./util";

const ContentInit = () => {
  return (
    <div className="w-full  max-w-[660px] justify-self-end flex flex-col justify-center">
      <div className="grid sm:grid-cols-2  gap-4 xl:gap-8 text-white w-full relative">
        {productList.map((x) => (
          <Link
            to={`?code=${x.code}`}
            key={x.id}
            className="bg-primary px-4 py-8 rounded-2xl space-y-2 text-center font-roboto flex flex-col items-center"
          >
            <div className=" text-4xl font-bold ">{x.price}</div>
            <div>
              <div className=" text-2xl font-bold">{x.name}</div>
              <p className=" text-lg font-normal">
                Unlimited data access <br /> {x.desc}
              </p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ContentInit;
