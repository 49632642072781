interface Props {
  title?: string;
  className?: string;
  disabled?: boolean;
}

const BtnSubmit: React.FC<Props> = ({ disabled, title, className }) => {
  return (
    <input
      disabled={disabled}
      type="submit"
      className={[
        " rounded-xl bg-primary py-2 text-lg font-medium  text-white  text-center w-full shadow-md",
        disabled ? "bg-opacity-50 text-opacity-50" : "cursor-pointer",
        className,
      ].join(" ")}
      value={title}
    />
  );
};

export default BtnSubmit;
