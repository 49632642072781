interface Props {
  title?: string;
  className?: string;
  runAction(): void;
  disabled?: boolean;
  isOutline?: boolean;
}

const BtnAction: React.FC<Props> = ({
  title,
  className,
  runAction,
  disabled,
  isOutline,
}) => {
  return (
    <button
      type="button"
      disabled={disabled}
      className={[
        "text-base text-center font-bold uppercase ",
        "my-2 p-[10px] rounded-xl max-w-md w-full  border ",
        isOutline
          ? "border-[#616467] text-[#616467]"
          : "border-transparent bg-primary text-white",
        isOutline && "hover:bg-primary",
        disabled ? "bg-opacity-50 opacity-50" : "cursor-pointer",
        className,
      ].join(" ")}
      onClick={() => runAction()}
    >
      {title}
    </button>
  );
};

export default BtnAction;
