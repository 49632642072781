import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowBackIcon } from "src/assets/icons/arrow-back.svg";
import { PurchaseModel } from "src/models/PurchaseModel";
import EpaygamesButton from "./EpaygamesButton";
const ContentConfirm = ({ confirmData }: { confirmData: PurchaseModel }) => {
  const navigate = useNavigate();

  return (
    <div className=" flex flex-col justify-center">
      <div className="w-full relative p-8 flex flex-col shadow-[0px_4px_7px_rgba(0,_0,_0,_0.1)] rounded-xl xl:max-w-[470px] space-y-6 xl:self-end bg-white">
        <div className="flex items-center justify-between text-custom-black w-full">
          <ArrowBackIcon
            role={"button"}
            onClick={() => navigate(-1)}
            className=" h-7 w-7 "
          />
          <div className=" text-lg  font-medium">Prepaid WiFi</div>
        </div>
        <div className="bg-primary  p-8 rounded-2xl space-y-2 font-roboto flex flex-col  text-white">
          <div className=" text-4xl font-bold ">
            ₱{confirmData?.amount?.toFixed(2)}
          </div>
          <div>
            <div className=" text-[1.75rem] font-bold">{confirmData?.name}</div>
            {/* <p className=" text-lg font-normal">{confirmData?.desc}</p> */}
          </div>
        </div>
        <div className="flex flex-col space-y-4 py-4">
          <div className="flex items-center justify-between">
            <div>Mobile number</div>
            <div>
              {"+63 " +
                confirmData?.mobile_number.replace(
                  /(\d{3})(\d{4})(\d{3})/,
                  "$1 $2 $3"
                )}
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div>Email</div>
            {confirmData?.email ? (
              <div>{confirmData?.email}</div>
            ) : (
              <em>No entered email</em>
            )}
          </div>
        </div>
        <p className=" text-base">
          After clicking the Confirm button, you will be redirected to a secure
          payment gateway window.
        </p>

        <EpaygamesButton
          success_url={`${window.location.origin}?reference_id=$reference_id&mobile_number=$mobile_number`}
          failed_url={`${window.location.origin}?status=failed`}
          payment_link={confirmData.checkout_link}
          disabled={!confirmData.checkout_link}
          btn_title="Confirm"
          btn_classname={[
            "rounded-xl bg-primary py-2 text-lg font-medium  text-white  text-center w-full shadow-md",
            !confirmData.checkout_link ? "opacity-50" : "cursor-pointer",
          ].join(" ")}
        />
      </div>
    </div>
  );
};

export default ContentConfirm;
