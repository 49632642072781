import { Link } from "react-router-dom";
import { BtnLink, Img } from "src/components/elements";
import { ReactComponent as HeadPhoneIcon } from "src/assets/icons/headphones.svg";
import { ReactComponent as MenuIcon } from "src/assets/icons/menu.svg";

const Nav = () => {
  return (
    <nav className="flex items-center justify-between h-[7rem] fixed inset-x-0  max-w-[1248px] w-full mx-auto px-4 top-0 bg-background z-40">
      <Link to={"/"}>
        <Img
          src="/images/fatpipe.svg"
          alt="fatpipe"
          width="244"
          height="52"
          className=" max-w-[10.75rem] lg:max-w-none object-contain"
        />
      </Link>
      <div className="flex items-center space-x-4 lg:hidden">
        <MenuIcon className="h-8 w-8 text-[#707479]" />
        <HeadPhoneIcon className="h-8 w-8 text-primary" />
      </div>
      <div className="hidden lg:flex items-center whitespace-nowrap space-x-8">
        <Link to={"/"}>Other Services</Link>
        <BtnLink
          title="Contact Support"
          linkTo="/"
          prefixIcon={<HeadPhoneIcon className="h-6 w-6 text-white" />}
        />
      </div>
    </nav>
  );
};

export default Nav;
